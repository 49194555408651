import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';

const StyledRow = styled.tr`
  border-bottom: ${({ theme }) => theme.table.border};
  display: flex;
  flex-direction: row;

  :first-child th {
    padding-top: ${({ theme }) => theme.table.header.paddingTop};
  }

  :first-child td {
    padding-top: ${({ theme }) => theme.table.data.paddingTop};
  }

  :last-child th {
    padding-bottom: ${({ theme }) => theme.table.header.paddingBottom};
  }

  :last-child td {
    padding-bottom: ${({ theme }) => theme.table.data.paddingBottom};
  }

  ${isMobile(css`
    border-bottom: ${({ theme }) => theme.table.border};
    border-top: ${({ theme }) => theme.table.border};
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing.small};
  `)}
`;

export interface KeyValueRowProps extends MergeElementProps<'tr'> {}

const KeyValueRow: FC<React.PropsWithChildren<KeyValueRowProps>> = ({ children, ...rest }) => (
  <StyledRow role="row" {...rest}>
    {children}
  </StyledRow>
);

export default KeyValueRow;
