import ReactDOM from 'react-dom/client';
import { StyleSheetManager } from 'styled-components';
import React from 'react';
import { APS_TOOLBOX, NeonStateProvider } from '@amzn/aps-neon-shared';
import { Layout } from './components/Layout';

if (!document.body.classList.contains(APS_TOOLBOX)) {
  try {
    document.body?.classList?.add(APS_TOOLBOX);
    const reactContainer = document.createElement('div');
    reactContainer.id = APS_TOOLBOX;

    const fixedToolbarMode = document.body.id === '__apstoolbar_fixed_';
    if (fixedToolbarMode) {
      document.body.replaceChildren(reactContainer);
    } else {
      document.body.appendChild(reactContainer);
    }

    // create a shadow root inside it
    const shadowRoot = reactContainer.attachShadow({ mode: 'open' });

    const root = ReactDOM.createRoot(shadowRoot);

    root.render(
      <StyleSheetManager target={shadowRoot}>
        <NeonStateProvider>
          <Layout root={root} rootElement={reactContainer} floating={!fixedToolbarMode} shadowRoot={shadowRoot} />
        </NeonStateProvider>
      </StyleSheetManager>
    );
    document.body.dispatchEvent(new CustomEvent(`${APS_TOOLBOX}_loaded`));
  } catch (_e: unknown) {
    document.body?.classList?.remove(APS_TOOLBOX);
    // Fire a pixel later.
  }
} else {
  console.error('APS Toolbox Already Present');
}
