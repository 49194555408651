import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';

const StyledCell = styled.td`
  flex: 3 0;
  background: ${({ theme }) => theme.table.data.bg};
  color: ${({ theme }) => theme.table.data.color};
  font-size: ${({ theme }) => theme.table.data.fontSize};
  font-weight: ${({ theme }) => theme.table.data.fontWeight};
  padding-top: ${({ theme }) => theme.table.data.paddingTop};
  padding-right: ${({ theme }) => theme.table.data.paddingRight};
  padding-bottom: ${({ theme }) => theme.table.data.paddingBottom};
  padding-left: ${({ theme }) => theme.table.data.paddingLeft};
  text-align: start;
  vertical-align: top;

  ${isMobile(css`
    flex: 1 0;
    font-size: ${({ theme }) => theme.table.mobile.data.fontSize};
  `)}

  :last-child {
    padding-right: ${({ theme }) => theme.table.data.paddingRight};
  }
`;
export interface ValueCellProps extends MergeElementProps<'td'> {}

const ValueCell: FC<React.PropsWithChildren<ValueCellProps>> = ({ children, ...rest }) => (
  <StyledCell role="cell" {...rest}>
    {children}
  </StyledCell>
);

export default ValueCell;
