import { Table } from '@amzn/storm-ui';
import React from 'react';
import styled, { css } from 'styled-components';

export interface ColumnDefinition {
  colId: string;
  minWidth: number;
  field: string;
  headerName: string;
  cellRenderer?: (params: any) => React.ReactNode;
}

export interface RowData {
  [key: string]: any;
}

export interface TableProps {
  data: RowData[];
  columnDefs: ColumnDefinition[];
  onRowSelect?: (row: RowData, rowIndex: number) => void;
  style?: React.CSSProperties;
}

const hoverHighlightMixin = css`
  table&>tbody>tr: hover {
    background-color: ${({ theme }) => theme.palette.zumthor};
  }
  tr.selected {
    background: ${({ theme }) => theme.palette.zumthor};
  }
`;

const CustomStyledTable = styled(Table)`
  ${hoverHighlightMixin}
  width: '100%'
`;

export const CustomTable: React.FC<TableProps> = ({ style, data, columnDefs, onRowSelect }) => {
  const [selectedRowId, setSelectedRowId] = React.useState<number>();

  const onSelect = (row: RowData, rowIndex: number) => {
    setSelectedRowId(rowIndex);
    if (onRowSelect) {
      onRowSelect(row, rowIndex);
    }
  };
  return (
    <CustomStyledTable style={style}>
      <thead>
        <tr>
          {columnDefs.map((column) => (
            <th key={column.colId}>{column.headerName}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex} className={selectedRowId === rowIndex ? 'selected' : ''}>
            {columnDefs.map((column) => (
              <td
                key={column.colId}
                onClick={() => onSelect(row, rowIndex)}
                style={{ whiteSpace: 'nowrap' }}
              >
                {typeof column.cellRenderer === 'function'
                  ? column.cellRenderer({ data: row, index: rowIndex })
                  : row[column.field]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </CustomStyledTable>
  );
};
