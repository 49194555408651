/* eslint-disable */
import { finder } from '@medv/finder';
interface DomSelectorProps {
  namespace: string;
  borderWidth: number;
  onClick: ((this: GlobalEventHandlers, ev: MouseEvent) => any) | null;
  filter: any;
  dontStop?: boolean;
  stopOnClick?: boolean;
}

interface ElementsWrapper {
  label?: HTMLElement;
  top?: HTMLElement;
  right?: HTMLElement;
  bottom?: HTMLElement;
  left?: HTMLElement;
  window?: any;
}

interface DomSelectorSelf {
  opts: DomSelectorProps;
  keyCodes: any;
  active: boolean;
  initialized: boolean;
  elements: ElementsWrapper;
}

interface DomSelectorAPI {
  element: HTMLElement | undefined;
  start: () => void;
  stop: () => void;
}

export function DomSelector(options: DomSelectorProps) {
  options = options || {};

  var pub: DomSelectorAPI = {
    element: undefined,

    start: function () {
      initStylesheet();
      if (self.active !== true) {
        self.active = true;
        createOutlineElements();
        var body: HTMLElement | null = document.querySelector('body');
        if (!body) return;
        body.style.cursor = 'crosshair';
        body.style.opacity = '0.7';
        body.querySelectorAll<HTMLElement>('div[id]').forEach((node: HTMLElement) => {
          if (node.id.indexOf('neon') < 0) node?.style?.setProperty('border', '2px solid green');
        });
        body.addEventListener('mousemove', updateOutlinePosition);
        body.addEventListener('keyup', stopOnEscape);
        if (self.opts.onClick) {
          setTimeout(function () {
            body?.addEventListener('click', filterOption);
          }, 50);
        }
      }
    },

    stop: function () {
      self.active = false;
      removeOutlineElements();
      var body = document.querySelector('body');
      if (!body) return;
      body.style.cursor = 'unset';
      body.style.opacity = 'unset';
      body.querySelectorAll<HTMLElement>('div[id]').forEach((node: HTMLElement) => {
        if (node.id.indexOf('neon') < 0) node?.style?.setProperty('border', 'revert');
      });
      body.removeEventListener('mousemove', updateOutlinePosition);
      body.removeEventListener('keyup', stopOnEscape);
      body.removeEventListener('click', filterOption);
    },
  };
  var self: DomSelectorSelf = {
    opts: {
      namespace: options.namespace || 'DomOutline',
      borderWidth: options.borderWidth || 2,
      onClick: options.onClick || null,
      filter: options.filter || false,
      dontStop: !options.stopOnClick || false,
    },
    keyCodes: {
      BACKSPACE: 8,
      ESC: 27,
      DELETE: 46,
    },
    active: false,
    initialized: false,
    elements: {},
  };

  function writeStylesheet(css: string) {
    var element: HTMLStyleElement = document.createElement('style');
    element.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(element);
    element.innerHTML = css;
  }

  function initStylesheet() {
    if (self.initialized !== true) {
      var css =
        '' +
        '.' +
        self.opts.namespace +
        ' {' +
        '    background: #f8991c;' +
        '    position: absolute;' +
        '    z-index: 999998;' +
        '}' +
        '.' +
        self.opts.namespace +
        '_label {' +
        '    background: #f8991c;' +
        '    border-radius: 2px;' +
        '    color: #002e35;' +
        '    font: bold 12px/12px Helvetica, sans-serif;' +
        '    padding: 4px 6px;' +
        '    position: absolute;' +
        '    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);' +
        '    z-index: 999999;' +
        '}';

      writeStylesheet(css);
      self.initialized = true;
    }
  }

  function createOutlineElements() {
    var divLabel = document.createElement('div');
    divLabel.classList.add(self.opts.namespace + '_label');

    var divTop = document.createElement('div');
    divTop.classList.add(self.opts.namespace);

    var divBottom = document.createElement('div');
    divBottom.classList.add(self.opts.namespace);

    var divLeft = document.createElement('div');
    divLeft.classList.add(self.opts.namespace);

    var divRight = document.createElement('div');
    divRight.classList.add(self.opts.namespace);

    var el = document.querySelector('body');
    if (el) {
      self.elements.label = el.appendChild(divLabel);
      self.elements.top = el.appendChild(divTop);
      self.elements.bottom = el.appendChild(divBottom);
      self.elements.left = el.appendChild(divLeft);
      self.elements.right = el.appendChild(divRight);
    }
  }

  function removeOutlineElements() {
    self.elements.bottom?.remove();
    self.elements.left?.remove();
    self.elements.right?.remove();
    self.elements.top?.remove();
    self.elements.label?.remove();
  }

  function compileLabelText(element: HTMLElement | undefined, width: number, height: number) {
    var label = element?.tagName.toLowerCase();
    if (element?.id) {
      label += '#' + element.id;
    }
    if (element?.className) {
      label += ('.' + element.className.trim().replace(/ /g, '.')).replace(/\.\.+/g, '.');
    }
    return label + ' (' + Math.round(width) + 'x' + Math.round(height) + ')';
  }

  function getScrollTop(e: any) {
    if (!self.elements.window) {
      self.elements.window = window;
    }

    return window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }

  function updateStyle(
    element: HTMLElement | undefined,
    top: string,
    left: string,
    width?: string | null,
    height?: string | null,
    content?: string | undefined
  ) {
    if (element) {
      element.style.top = top;
      element.style.left = left;
      if (width) element.style.width = width;
      if (height) element.style.height = height;
      if (content) element.textContent = content;
    }
  }

  function updateOutlinePosition(e: Event) {
    if (e.target instanceof HTMLElement) {
      // && e.target.id
      if (e.target?.className.indexOf(self.opts.namespace) !== -1) {
        return;
      }
      if (self.opts.filter) {
        if (!e.target.tagName === self.opts.filter) {
          return;
        }
      }
      pub.element = e.target;

      var b = self.opts.borderWidth;
      var scroll_top = getScrollTop(e);
      var pos = pub.element?.getBoundingClientRect();
      if (!pos) return;
      var top = pos.top + scroll_top;

      var label_text = compileLabelText(pub.element, pos.width, pos.height);
      var label_top = Math.max(0, top - 20 - b, scroll_top) + 'px';
      var label_left = Math.max(0, pos.left - b) + 'px';

      updateStyle(self.elements.label, label_top, label_left, null, null, label_text);
      updateStyle(
        self.elements.top,
        Math.max(0, top - b) + 'px',
        pos.left - b + 'px',
        pos.width + b + 'px',
        b + 'px'
      );
      updateStyle(
        self.elements.bottom,
        top + pos.height + 'px',
        pos.left - b + 'px',
        pos.width + b + 'px',
        b + 'px'
      );
      updateStyle(
        self.elements.left,
        top - b + 'px',
        Math.max(0, pos.left - b) + 'px',
        b + 'px',
        pos.height + b + 'px'
      );
      updateStyle(
        self.elements.right,
        top - b + 'px',
        pos.left + pos.width + 'px',
        b + 'px',
        pos.height + b * 2 + 'px'
      );
    }
  }

  function stopOnEscape(e: { keyCode: any }) {
    if (
      e.keyCode === self.keyCodes.ESC ||
      e.keyCode === self.keyCodes.BACKSPACE ||
      e.keyCode === self.keyCodes.DELETE
    ) {
      pub.stop();
    }

    return false;
  }

  function clickHandler(e: any) {
    if (!self.opts.dontStop) pub.stop();
    if (self.opts.onClick && pub.element) self.opts.onClick.call(pub.element, e);

    return false;
  }

  function filterOption(this: any, e: any) {
    if (self.opts.filter) {
      if (!e.target.tagName === self.opts.filter) {
        return false;
      }
    }
    clickHandler.call(this, e);
    return true;
  }

  return pub;
}

export function generateUniqueSelector(element: Element): string | undefined {
  return finder(element, {
    root: document.body, // Root of search, defaults to document.body.
    idName: (name) => true, // Check if this ID can be used.
    className: (name) => true, // Check if this class name can be used.
    tagName: (name) => true, // Check if tag name can be used.
    attr: (name, value) => false, // Check if attr name can be used.
    seedMinLength: 1,
    optimizedMinLength: 2,
    threshold: 1000,
    maxNumberOfTries: 10_000,
  });
}
