import { StrictRJSFSchema } from '@rjsf/utils';
import { Dispatch } from 'react';

export interface NeonMenuApplication {
  id: string; // Unique ID of the application
  icon: React.ReactNode; // Icon for the app
  title?: string | React.ReactNode; // App Title
  content: React.ReactNode; // Main content of the app
  type?: string; // Defaults to settings,
  access?: string[]; // Provides an array of roles for which this app should be accessible.
  features?: {
    dom?: boolean;
    showHeader?: boolean;
  };
  events: {
    // Constant list of all the events for application
    [key: string]: string;
  };
  order?: number; // Menu Order
  onClick?: () => void; // Function that may be executed if there are no contents.
  description?: string; // Description for the plugin, used for documentation.
  active?: boolean; // Active inactive flag.
  dataSchema?: StrictRJSFSchema; // JSONSchema representation of data model
}

export type ApplicationState = {
  apps: Record<string, NeonMenuApplication>;
  floating: boolean;
  size: {
    width: number | string;
    height: number;
  };
  appData: {
    [key: string]: unknown;
  };
  log: (message?: unknown, ...optionalParams: unknown[]) => void;
  shadowRoot?: ShadowRoot;
  trackEvent: (eventCategory: string, eventName: string, eventProperties: Object) => void;
};

type ApplicationActionState = {
  type: 'SET_STATE' | 'SET_APP';
  state: Partial<ApplicationState>;
};

type ApplicationActionAppData = {
  type: 'SET_APPDATA';
  app: string;
  appData: unknown;
};

export type ApplicationAction = ApplicationActionState | ApplicationActionAppData;

export interface ApplicationContext extends ApplicationState {
  dispatch: Dispatch<ApplicationAction>;
}
