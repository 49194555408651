import { useContext, useEffect, useRef } from 'react';
import { NumberSize, Resizable } from 're-resizable';
import { Context } from '@amzn/aps-neon-shared';

interface FloatingContainerProps {
  children: React.ReactNode;
  floating?: boolean;
}

export function FloatingContainer({ children, floating = false }: FloatingContainerProps) {
  const { size, dispatch, log } = useContext(Context);
  const parentRef = useRef<Resizable>(null);

  useEffect(() => {
    parentRef.current?.updateSize(size);
    if (floating) {
      document.body.style.paddingBottom = `${size.height}px`;
    }
  }, [size, floating]);

  const onResizeStop = (
    event: MouseEvent | TouchEvent,
    direction: unknown,
    elementRef: HTMLElement,
    d: NumberSize
  ) => {
    log('onResizeStop', size, d);
    dispatch({
      type: 'SET_STATE',
      state: {
        size: {
          width: size.width,
          height: size.height + d.height,
        },
      },
    });
  };

  return (
    <>
      {floating ? (
        <div
          style={{
            all: 'initial',
            width: '100%',
            position: 'fixed',
            bottom: '0',
            left: '0',
            zIndex: '2147483646',
            overflow: 'hidden',
            fontFamily: 'system-ui, sans-serif',
          }}
        >
          <Resizable
            onResizeStop={onResizeStop}
            ref={parentRef}
            defaultSize={size}
            minHeight={60}
            minWidth="100%"
            maxHeight="100vh"
            enable={{
              top: true,
              right: false,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
          >
            {children}
          </Resizable>
        </div>
      ) : (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            maxHeight: '100vh',
            fontFamily: 'system-ui, sans-serif',
          }}
        >
          {children}
        </div>
      )}
    </>
  );
}

export default FloatingContainerProps;
