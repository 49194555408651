import React from 'react';
import {
  CategoryHeaderText,
  CardContent,
  SuccessTag,
  CardHeader,
  CardFooter,
  StyledCard,
  CardHeaderContentRight,
} from './StyledCardComponents';

export interface ProgramCardTemplateProps {
  children: JSX.Element[] | JSX.Element;
  header?: Header;
  footer?: JSX.Element;
  style?: React.CSSProperties;
}

export interface Header {
  text: string;
  status?: string;
  statusString?: string;
}

const getCardComponent = (
  children:
    | string
    | number
    | boolean
    | JSX.Element
    | React.ReactFragment
    | JSX.Element[]
    | null
    | undefined,
  header?: Header | undefined,
  footer?: JSX.Element | React.ReactFragment | JSX.Element[]
) => (
  <>
    {!!header && (
      <CardHeader>
        <CategoryHeaderText type="h2" styleAs="h4" fontSize="mini">
          {header.text}
        </CategoryHeaderText>
        <CardHeaderContentRight>
          {header.status && header.status === 'SUCCESS' && header.statusString && (
            <SuccessTag label={header.statusString} description={header.statusString} />
          )}
        </CardHeaderContentRight>
      </CardHeader>
    )}
    <CardContent>{children}</CardContent>
    {!!footer && <CardFooter>{footer}</CardFooter>}
  </>
);

export const ProgramBaseCard: React.FC<ProgramCardTemplateProps> = ({
  children,
  header,
  footer,
  style,
}: ProgramCardTemplateProps) => {
  return <StyledCard style={style}>{getCardComponent(children, header, footer)}</StyledCard>;
};
