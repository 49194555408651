import React, { createContext, useReducer, ReactNode } from 'react';
import { ApplicationState, ApplicationContext, ApplicationAction } from './ApplicationState';
import { trackEvent, log } from './Utility';

const initialState: ApplicationState = {
  apps: {},
  size: { width: '100%', height: 60 },
  floating: false,
  appData: {},
  log,
  trackEvent,
};

const Context = createContext<ApplicationContext>(initialState as ApplicationContext);

const { Provider } = Context;

export const NeonStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const reducer = (state: ApplicationState, action: ApplicationAction): ApplicationState => {
    state.log(`Dispatch`, action);
    switch (action.type) {
      case 'SET_STATE':
        return { ...state, ...action.state };
      case 'SET_APP':
        return { ...state, apps: { ...state.apps, ...action.state.apps } };
      case 'SET_APPDATA':
        return action.app && action.appData && action.appData
          ? { ...state, appData: { ...state.appData, [action.app]: action.appData } }
          : { ...state };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ ...state, dispatch }}>{children}</Provider>;
};

export { Context };
export * from './Constants';
export * from './ApplicationState';
export * from './EventManager';
export * from './Utility';
