import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { MergeElementProps } from '@amzn/storm-ui-utils';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';

const StyledTable = styled.table`
  width: 100%;
  border-top: ${({ theme }) => theme.table.border};

  ${isMobile(css`
    border-top: none;
  `)}
`;

const StyledCaption = styled.caption`
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
`;

export interface KeyValueTableProps extends MergeElementProps<'table'> {
  caption: string;
}

const KeyValueTable: FC<React.PropsWithChildren<KeyValueTableProps>> = ({ caption, children, ...rest }) => (
  <StyledTable role="table" cellPadding="0" cellSpacing="0" {...rest}>
    <StyledCaption>{caption}</StyledCaption>
    <tbody>{children}</tbody>
  </StyledTable>
);

export default KeyValueTable;
