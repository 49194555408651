import { TAHOE_PROD, TAHOE_BETA } from './index';

export function log(message?: unknown, ...optionalParams: unknown[]) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(
      '%c APS_TOOLBOX ',
      'color: orange;background:#002e35;padding:2px;border-radius:10px',
      message,
      ...optionalParams
    );
  }
}

/**
 *
 * @param eventCategory Category of Event
 * @param eventName Name of Event
 * @param eventProperties JSON Object as properties to be send along with event.
 */
export function trackEvent(eventCategory: string, eventName: string, eventProperties: Object = {}) {
  try {
    let urlData = {};

    try {
      urlData = {
        url: window.location.href,
      };
    } catch (e) {
      // Silence is Golden
    }

    const eventPayload = JSON.stringify({
      eventSource: 'apstoolbox',
      eventCategory,
      eventName,
      eventTime: String(Date.now()),
      eventProperties: { ...urlData, ...eventProperties },
    });

    const encodedEventPayload = window.btoa(eventPayload);
    let _url =
      'https://prod.tahoe-analytics.publishers.advertising.a2z.com/logevent/postEquinoxEvent';
    let _key = TAHOE_PROD;

    if (process.env.NODE_ENV !== 'production') {
      _url =
        'https://beta.tahoe-analytics.publishers.advertising.a2z.com/logevent/postEquinoxEvent';
      _key = TAHOE_BETA;
    }

    const requestBody = {
      Data: encodedEventPayload,
      PartitionKey: String(Date.now()),
    };

    const xhr = new XMLHttpRequest();
    xhr.open('POST', _url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', _key);
    xhr.send(JSON.stringify(requestBody));
  } catch (e) {
    // Silence is Golden
  }
}
