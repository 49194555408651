import styled from 'styled-components';
import { Tag, Text } from '@amzn/storm-ui';

export const CardHeader = styled.div`
  height: 30px;
  display: flex;
  flex-wrap: nowrap;
  padding-top: 8px;
  top: ${({ theme }) => theme.spacing.medium};
`;
export const CategoryHeaderText = styled(Text)`
  color: ${({ theme }) => theme.palette.indigo};
  text-transform: uppercase;
  padding-right: ${({ theme }) => `${theme.spacing.micro}`};
`;

export const SuccessTag = styled(Tag)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.color.success};
`;

export const CardHeaderContentRight = styled.div`
  margin-inline-start: auto;
  margin-top: -4px;
`;
export const CardContent = styled.div`
  max-height: 85%;
  flex: 1;
  padding-bottom: ${({ theme }) => `${theme.spacing.medium}`};
`;
CardContent.displayName = 'CardContent';

export const CardFooter = styled.div`
    width:100%;
    display:flex;
    height: 15px;
    align-items:center;
    padding: ${({ theme }) => `${theme.spacing.medium} 0px`} ;
    border-top: ${({ theme }) => `2px solid ${theme.palette.haze}`};
  }
`;
CardFooter.displayName = 'CardFooter';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding: 10px 22px;
  border: ${({ theme }) => `1px solid ${theme.palette.mercury}`};
  border-top: ${({ theme }) => `3px solid ${theme.globals.link.activeColor}`};
  box-shadow: ${({ theme }) => `0 1px 1px 0 ${theme.palette.silver}`};
  background: ${({ theme }) => `${theme.palette.white}`};
  border-radius: 3px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

StyledCard.displayName = 'StyledCard';
