import React from 'react';
import styled, { css } from 'styled-components';
import { MergeStyledComponentElementProps } from '@amzn/storm-ui-utils';
import isMobile from '../../theme/style-mixins/isMobile/isMobile';
import { TaktProps } from '../../types/TaktProps';
import { TaktIdProvider, createStormTaktId } from '../../TaktIdContext';

export interface TableProps extends MergeStyledComponentElementProps<'table'>, TaktProps {}

/** The Table component to export */
const HtmlTable = styled('table').attrs(() => ({
  role: 'table',
  cellPadding: '0',
  cellSpacing: '0',
}))`
  table& {
    display: table;
    width: initial;
    border-collapse: collapse;
    border: ${({ theme }) => theme.table.border};
    line-height: 19px;
    > thead > tr > td,
    > thead > tr > th,
    > tbody > tr > th {
      background: ${({ theme }) => theme.table.header.bg};
      color: ${({ theme }) => theme.table.header.color};
      font-size: ${({ theme }) => theme.table.header.fontSize};
      font-weight: 400;
      text-align: start;
      vertical-align: top;
      border: ${({ theme }) => theme.table.header.border};
      padding-top: ${({ theme }) => theme.table.header.paddingTop};
      padding-right: ${({ theme }) => theme.table.header.paddingRight};
      padding-bottom: ${({ theme }) => theme.table.header.paddingBottom};
      padding-left: ${({ theme }) => theme.table.header.paddingLeft};
      ${isMobile(css`
        font-size: ${({ theme }) => theme.table.mobile.header.fontSize};
      `)}
    }
    > tbody > tr > td {
        color: ${({ theme }) => theme.table.data.color};
        font-size: ${({ theme }) => theme.table.data.fontSize};
        font-weight: ${({ theme }) => theme.table.data.fontWeight};
        text-align: start;
        vertical-align: middle;
        border: ${({ theme }) => theme.table.data.border};
        padding-top: ${({ theme }) => theme.table.data.paddingTop};
        padding-right: ${({ theme }) => theme.table.data.paddingRight};
        padding-bottom: ${({ theme }) => theme.table.data.paddingBottom};
        padding-left: ${({ theme }) => theme.table.data.paddingLeft};
        ${isMobile(css`
          font-size: ${({ theme }) => theme.table.mobile.data.fontSize};
        `)}
      }
    }
    > tfoot > tr > td {
      background: ${({ theme }) => theme.table.header.bg};
      color: ${({ theme }) => theme.table.header.color};
      font-size: ${({ theme }) => theme.table.header.fontSize};
      font-weight: bold;
      text-align: start;
      border: ${({ theme }) => theme.table.border};
      padding-top: ${({ theme }) => theme.table.header.paddingTop};
      padding-right: ${({ theme }) => theme.table.header.paddingRight};
      padding-bottom: ${({ theme }) => theme.table.header.paddingBottom};
      padding-left: ${({ theme }) => theme.table.header.paddingLeft};
      ${isMobile(css`
        font-size: ${({ theme }) => theme.table.mobile.header.fontSize};
      `)}
    }
  }
`;

const Table = ({ taktId, taktValue, ...props }:TableProps): JSX.Element => (
  <TaktIdProvider taktId={taktId} taktValue={taktValue} fallbackId={createStormTaktId('table')}>
    <HtmlTable {...props} />
  </TaktIdProvider>
)

const StyledTable = styled(Table)``;

export default StyledTable;
