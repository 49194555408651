export type MockedPlacements = {
  selectedElementSelector: string;
  selectedElement?: HTMLElement;
  selectedElementStyles?: {
    [key: string]: any;
  };
  location: InsertPosition;
  status: STATUS;
  containerStatus: STATUS;
  id: string;
  template?: TEMPLATE;
  replaceSelection?: boolean;
};

export enum TEMPLATE {
  ROME_PROD,
  ROME,
  MOCK,
  TOKYO,
  LONDON
}

export type MockedPlacementData = {
  [key: string]: MockedPlacements;
};
export type PluginData = {
  mockedPlacements: MockedPlacementData;
};

export enum STATUS {
  NEW,
  RENDERED,
  REMOVED,
}
