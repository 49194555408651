import { deepMerge, MergeableObject } from '@amzn/storm-ui-utils';
import { Theme as defaultThemeType } from '../themes/default';
import mobileTheme, { Theme as MobileThemeType } from '../themes/mobile';
import { Theme as AnyTheme } from '../themes/types';
import MobileMode from './MobileMode';
import isMobileMediaQuery from './isMobileMediaQuery';

type ComposedDefaultTheme = AnyTheme & defaultThemeType;

export type Theme = ComposedDefaultTheme
  & MobileThemeType
  & { mode: MobileMode }

function createMobileTheme(mode: MobileMode) {
  return function mobileThemeGenerator(theme: defaultThemeType): Theme {
    return deepMerge(
      {},
      (theme as MergeableObject),
      (mobileTheme as MergeableObject),
      {
        mobile: {
          mode,
          /* copy over the mobile mediaQuery if we are provided one */
          ...(isMobileMediaQuery(theme)
            ? { mediaQuery: (theme as Theme)?.mobile?.mediaQuery }
            : {}),
        },
      },
    ) as Theme;
  };
}

const mobileAutoTheme = createMobileTheme(MobileMode.Auto);
const mobileEnableTheme = createMobileTheme(MobileMode.Enable);
const mobileDisableTheme = createMobileTheme(MobileMode.Disable);

function getMobileTheme(mode: MobileMode) {
  switch (mode) {
    case MobileMode.Enable:
      return mobileEnableTheme;
    case MobileMode.Disable:
      return mobileDisableTheme;
  }
  /* default to auto mode */
  return mobileAutoTheme;
}

export default getMobileTheme;
