import { useContext, useEffect, useState } from 'react';
import Editor, { loader, EditorProps, DiffEditor, DiffEditorProps } from '@monaco-editor/react';
import { Context } from '../context';
// import monacoStyles from 'bundle-text:monaco-editor/min/vs/editor/editor.main.css';
// import * as monaco from 'monaco-editor';
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

/*
window.MonacoEnvironment = {
  getWorkerUrl(_, label) {
    if (label === 'json') {
      return './json.worker.js';
    }
    if (label === 'html' || label === 'handlebars') {
      return './html.worker.js';
    }
    return './editor.worker.js';
  },
};
* /
loader.config({
  monaco
});

loader.init();
*/

const addStyles = (shadowRoot: ShadowRoot) => {
  const styleTag: HTMLLinkElement = document.createElement('link');
  // styleTag.textContent = 'monacoStyles';
  // TODO: There is a parcel build issue using the bundle-text approach, this has to be hosted as static files and used directly.
  styleTag.setAttribute(
    'href',
    'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.40.0/min/vs/editor/editor.main.min.css'
  );
  styleTag.setAttribute('rel', 'stylesheet');
  shadowRoot.appendChild(styleTag);
};

export const MonacoEditor = (props: EditorProps) => {
  const { shadowRoot } = useContext(Context);
  const [styleAdded, setStyleAdded] = useState(false);

  useEffect(() => {
    // TODO: Instead of useEffect we need to use the state from context.
    if (!styleAdded && shadowRoot) {
      addStyles(shadowRoot);
      setStyleAdded(true);
    }
  }, [styleAdded, shadowRoot]);

  /* eslint-disable react/jsx-props-no-spreading */
  return <Editor {...props} theme="vs-dark" />;
};

export const MonacoDiffEditor = (props: DiffEditorProps) => {
  const { shadowRoot } = useContext(Context);
  const [styleAdded, setStyleAdded] = useState(false);

  useEffect(() => {
    // TODO: Instead of useEffect we need to use the state from context.
    if (!styleAdded && shadowRoot) {
      addStyles(shadowRoot);
      setStyleAdded(true);
    }
  }, [styleAdded, shadowRoot]);

  /* eslint-disable react/jsx-props-no-spreading */
  return <DiffEditor {...props} />;
};
