import { defaultTheme, MobileMode, MobileThemeProvider, ThemeProvider } from '@amzn/storm-ui';
import React, { useContext, useEffect } from 'react';
import { Root } from 'react-dom/client';
import {
  APS_TOOLBOX,
  APS_TOOLBOX_QUERY_PARAM_ADMIN,
  APS_TOOLBOX_QUERY_PARAM_DATA,
  APS_TOOLBOX_QUERY_PARAM_HIDE,
  NeonMenuApplication,
  Context,
} from '@amzn/aps-neon-shared';
import { FloatingContainer } from './FloatingContainer';
import NavigationApp from './NavigationApp';
import ErrorBoundary from './ErrorBoundary';

export interface LayoutProps {
  root: Root;
  floating: boolean;
  shadowRoot: ShadowRoot;
}

export const Layout = ({ root, floating, shadowRoot }: LayoutProps) => {
  const { dispatch, log, size, trackEvent } = useContext(Context);
  const queryParams = new URLSearchParams(window.location.search);
  const domFilter = (app: NeonMenuApplication) =>
    !floating ? !(app.features?.dom || false) : true;
  const activeFilter = (app: NeonMenuApplication) => app.active === undefined || app.active;

  useEffect(() => {
    const enableAdmin = queryParams.get(APS_TOOLBOX_QUERY_PARAM_ADMIN);
    const adminFilter = (app: NeonMenuApplication) =>
      enableAdmin ? true : !app.access?.includes('ADMIN');

    dispatch({
      type: 'SET_STATE',
      state: {
        floating,
        shadowRoot,
      },
    });

    import('../PluginConfig').then((pkg) => {
      Object.entries(pkg.plugins)
        .filter((app) => domFilter(app[1]) && adminFilter(app[1]) && activeFilter(app[1]))
        .forEach((packages) => {
          dispatch({
            type: 'SET_APP',
            state: {
              apps: { [packages[0]]: packages[1] },
            },
          });
        });
    });

    if (process.env.NEON_INTERNAL_BUILD === '1') {
      import('../InternalPluginConfig').then((pkg) => {
        Object.entries(pkg.plugins)
          .filter((app) => domFilter(app[1]) && adminFilter(app[1]) && activeFilter(app[1]))
          .forEach((packages) => {
            dispatch({
              type: 'SET_APP',
              state: {
                apps: { [packages[0]]: packages[1] },
              },
            });
          });
      });
    }

    const stateData = queryParams.get(APS_TOOLBOX_QUERY_PARAM_DATA);
    if (stateData && JSON.parse(window.atob(stateData))) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const _stateData = JSON.parse(window.atob(stateData));
      log('Adding application state from incoming data.');
      dispatch({
        type: 'SET_STATE',
        state: {
          appData: _stateData,
          size: {
            ...size,
            height: size.height + 0.1, // anything more than default should trigger render.
          },
        },
      });
      trackEvent('APP_LOAD', 'loadWithState', {
        state: _stateData,
      });
    } else {
      trackEvent('APP_LOAD', 'loadWithoutState', {});
    }
    const hide = queryParams.get(APS_TOOLBOX_QUERY_PARAM_HIDE);
    if (hide && hide === 'true') {
      const toolboxContainer = document.getElementById(APS_TOOLBOX);
      if (toolboxContainer) {
        toolboxContainer.style.display = 'none';
      }
    }
  }, [dispatch, floating]); // eslint-disable-line react-hooks/exhaustive-deps

  const overrideTheme = {
    ...defaultTheme,
    form: {
      ...defaultTheme.form,
      input: {
        ...defaultTheme.form.input,
      },
    },
    tooltip: {
      ...defaultTheme.tooltip,
      mouseLeaveTimeout: 0,
    },
  };

  return (
    <ErrorBoundary>
      <ThemeProvider theme={overrideTheme}>
        <MobileThemeProvider mobileMode={MobileMode.Auto}>
          <FloatingContainer floating={floating}>
            <NavigationApp root={root} />
          </FloatingContainer>
        </MobileThemeProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};
