import { APS_TOOLBOX } from "../context";

function getTagStyle(tagName: string) {
  const tags = document.getElementsByTagName(tagName);
  if (tags && tags[0]) {
    const tagStyle = window.getComputedStyle(document.getElementsByTagName(tagName)[0]);
    const output = new Map();
    const CONST_PAGE = '--aps-custom-page-';
    output.set(`${CONST_PAGE}${tagName}-color`, tagStyle.color);
    output.set(`${CONST_PAGE}${tagName}-fontFamily`, tagStyle.fontFamily);
    output.set(`${CONST_PAGE}${tagName}-fontSize`, tagStyle.fontSize);
    output.set(`${CONST_PAGE}${tagName}-fontWeight`, tagStyle.fontWeight);
    output.set(`${CONST_PAGE}${tagName}-lineHeight`, tagStyle.lineHeight);
    return Object.fromEntries(output); // eslint-disable-line typescript-compat/compat
  }
  return {};
}
const getPageStyles = (tags = ['p', 'h1', 'h2']) => {
  return tags
    .map(getTagStyle)
    .filter((t) => Object.keys(t).length > 0)
    .reduce((accumulator, currentObject) => {
      return { ...accumulator, ...currentObject };
    }, {});
};
export const getStyles = (element: HTMLElement, tags = ['p', 'h1', 'h2']) => {
  let styles = {};
  try {
    const y = window.getComputedStyle(element);
    const styleMap = new Map();
    styleMap.set('--aps-custom-container-width', y.width);
    styleMap.set('--aps-custom-container-margin', y.margin);
    styleMap.set('--aps-custom-container-padding', y.padding);
    styleMap.set('--aps-custom-container-background', y.background);
    styleMap.set('--aps-custom-container-color', y.color);
    styles = Object.fromEntries(styleMap.entries());
  } catch (e) {
    styles = {};
  }
  return {
    ...styles,
    ...getPageStyles(tags),
  };
};

export const apsCustomDefaultStyles = [
  { key: '--aps-custom-bgcolor', value: '#ffffff' },
  { key: '--aps-custom-color', value: '#000000' },
  { key: '--aps-custom-primary-font-family', value: 'Arial, sans-serif' },
  { key: '--aps-custom-primary-font-size', value: '20px' },
  { key: '--aps-custom-primary-font-weight', value: '700' },
  { key: '--aps-custom-primary-color', value: 'var(--aps-custom-color)' },
  { key: '--aps-custom-secondary-font-family', value: 'var(--aps-custom-primary-font-family)' },
  { key: '--aps-custom-secondary-color', value: 'var(--aps-custom-primary-color)' },
  { key: '--aps-custom-secondary-hover-color', value: '#C7511F' },
  { key: '--aps-custom-secondary-font-size', value: '16px' },
  { key: '--aps-custom-secondary-font-weight', value: '400' },
  { key: '--aps-custom-button-bgcolor', value: '#FFA41C' },
  { key: '--aps-custom-button-height', value: '33px' },
  { key: '--aps-custom-button-color', value: '#0F1111' },
  { key: '--aps-custom-button-font-family', value: "'Amazon Ember', Arial" },
  { key: '--aps-custom-button-font-size', value: '14px' },
  { key: '--aps-custom-button-font-weight', value: '400' },
  { key: '--aps-custom-button-background', value: 'var(--aps-custom-button-bgcolor)' },
  { key: '--aps-custom-button-border', value: '1px solid #FF8F00' },
  { key: '--aps-custom-button-transition', value: 'auto' },
  { key: '--aps-custom-button-border-color', value: 'var(--aps-custom-button-bgcolor)' },
  { key: '--aps-custom-button-border-radius', value: '20px' },
  { key: '--aps-custom-button-hover-background', value: 'var(--aps-custom-button-background)' },
  { key: '--aps-custom-button-hover-color', value: 'var(--aps-custom-button-color)' },
  { key: '--aps-custom-button-box-shadow', value: 'none' },
];

export function addCustomScript(shadowRoot: ShadowRoot | HTMLHeadElement, scriptPath: string) {
  const script = document.createElement('script');
  script.src = scriptPath;
  script.type = 'module';
  shadowRoot.appendChild(script);
}

export async function getUserFromApi(){
  const user =  await getDataFromApi({
    type: 'getUser'
  });
  return user;
}

export async function getDataFromApi(request: object) : Promise<{data: any, status: string}> {
  return new Promise((resolve, reject)=> {
      const channelId = `neon_${Math.random().toString(36).substring(2, 5)}`;
      const toolbox = document.getElementById(APS_TOOLBOX);
      if(!toolbox || toolbox.getAttribute('data-extension-status') !== 'ok')
        reject({reason: 'NO_EXTENSION'});
      const eventHandler = (response: any)=> {
          resolve(response.detail);
          toolbox?.removeEventListener(channelId, eventHandler);
      }
      toolbox?.addEventListener(channelId, eventHandler);
      const event = new CustomEvent('api', {
          detail: {
              response_channel: channelId,
              ...request
          }
      });
      if(!toolbox?.dispatchEvent(event)){
          reject('error')
          toolbox?.removeEventListener(channelId, eventHandler);
      }
  })
}
