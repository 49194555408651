import { KeyCell, KeyValueRow, KeyValueTable, Text, ValueCell } from '@amzn/storm-ui';

export interface TableWrapper {
  title: string | JSX.Element | undefined;
  children: TableRowsProps[];
}
export interface TableRowsProps {
  key: string;
  value: any;
}
export interface NestedTableProps {
  getTableMetadata: () => TableWrapper[];
}

export const NestedTable = ({ getTableMetadata }: NestedTableProps) => {
  return (
    <>
      {getTableMetadata().map((d, index) => (
        <div key={index}>
          <Text type="h2" style={{ marginTop: '1rem', padding: '0.5rem' }}>
            {d.title}
          </Text>
          <KeyValueTable caption="Favorites">
            {d.children.map((data) => (
              <KeyValueRow key={data.key}>
                <KeyCell>{data.key}</KeyCell>
                <ValueCell>{data.value || ''}</ValueCell>
              </KeyValueRow>
            ))}
          </KeyValueTable>
        </div>
      ))}
    </>
  );
};
