import { log } from './Utility';

export class EventManager {
  private static instance: EventManager;

  private listeners: Map<string, EventListener[]> = new Map();

  constructor() {}

  static getInstance(): EventManager {
    if (!EventManager.instance) {
      EventManager.instance = new EventManager();
    }

    return EventManager.instance;
  }

  addEventListener(type: string, listener: EventListener): void {
    if (!this.listeners.has(type)) {
      this.listeners.set(type, []);
    }

    this.listeners.get(type)?.push(listener);
  }

  removeEventListener(type: string, listener: EventListener): void {
    const listeners = this.listeners.get(type);
    if (listeners) {
      const index = listeners.indexOf(listener);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
    }
  }

  dispatchEvent(event: CustomEvent): void {
    const listeners = this.listeners.get(event.type);
    log(`<EVENT:${event.type}> `, event);
    if (listeners) {
      for (const listener of listeners) {
        listener(event);
      }
    }
  }
}
